import React from 'react';
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useLocales } from 'src/locales';
import { individualGradientTextStyle } from '../utils/utils';

export default function FAQSection() {
  const { t } = useLocales();

  const faqs = [
    {
      question: t('landingpage.faq.q1'),
      answer: t('landingpage.faq.a1'),
    },
    {
      question: t('landingpage.faq.q2'),
      answer: t('landingpage.faq.a2'),
    },
    {
      question: t('landingpage.faq.q3'),
      answer: t('landingpage.faq.a3'),
    },
    {
      question: t('landingpage.faq.q4'),
      answer: t('landingpage.faq.a4'),
    },
    {
      question: t('landingpage.faq.q5'),
      answer: t('landingpage.faq.a5'),
    },
    {
      question: t('landingpage.faq.q6'),
      answer: t('landingpage.faq.a6'),
    },
    {
      question: t('landingpage.faq.q7'),
      answer: t('landingpage.faq.a7'),
    },
  ];

  return (
    <Box>
      <Typography
        variant='h2'
        sx={{ ...individualGradientTextStyle, mb: 4 }}
      >
        {t('landingpage.faq.title')}
      </Typography>

      {faqs.map((faq, idx) => (
        <Accordion key={idx}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">{faq.question}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1">{faq.answer}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
}
