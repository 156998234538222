export const calculateMean = (arr) => {
    let sum = 0;
    let count = 0;

    for (let i = 0; i < arr.length; i += 1) {
        if (arr[i] !== null && arr[i] >= 0 && arr[i] <= 1) {
            sum += arr[i];
            count += 1;
        }
    }

    return count === 0 ? null : sum / count;
};

export const partners = {
    'https://www.bmbf.de': [
        '/assets/images/home/affiliations/bmbf.webp',
        'Federal Ministry of Education and Research, Germany',
    ],
    'https://cispa.de/en': [
        '/assets/images/home/affiliations/cispa.webp',
        'CISPA Helmholtz Center for Information Security, Germany',
    ],
    'https://saarland-informatics-campus.de/': [
        '/assets/images/home/affiliations/sic.webp',
        'Saarland Informatics Campus, Germany',
    ],
    'https://www.uds-triathlon.de/': [
        '/assets/images/home/affiliations/triathlon.webp',
        'Triathlon Ecosystem, Germany',
    ],
    'https://www.kleinpublic.de/': [
        '/assets/images/home/affiliations/klein-public.webp',
        'klein public advisory, Germany',
    ],
    'https://www.uni-saarland.de/start.html': [
        '/assets/images/home/affiliations/uds.webp',
        'Saarland University, Germany',
    ],
};

export const supporters = {
    'https://www.bmbf.de/': [
        '/assets/images/home/affiliations/bmbf.webp',
        'Federal Ministry of Education and Research, Germany',
    ],
    'https://cispa.de/en': [
        '/assets/images/home/affiliations/cispa.webp',
        'CISPA Helmholtz Center for Information Security, Germany',
    ],
    'https://www.helmholtz.de/': [
        '/assets/images/home/affiliations/helmholtz.webp',
        'Helmholtz Association, Germany',
    ],
};

export const individualGradientTextStyle = {
    fontWeight: 'bold',
    background: '-webkit-linear-gradient(260deg, #007bff 0%, #000 100%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    textAlign: 'center',
};
