import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
// utils
//
//
import translationDe from './langs/de.json';
import translationEn from './langs/en.json';

// ----------------------------------------------------------------------

// const lng = localStorageGetItem('i18nextLng', defaultLang.value);
const supportedLanguages = ['en', 'de'];
const systemLanguage = navigator.language.split('-')[0];
const initialLanguage = supportedLanguages.includes(systemLanguage) ? systemLanguage : 'en';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: initialLanguage,
    fallbackLng: 'en',
    resources: {
      en: { translations: translationEn },
      de: { translations: translationDe }
    },
    debug: false,
    ns: ['translations'],
    defaultNS: 'translations',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
