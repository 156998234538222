import { Grid, Stack, Typography } from '@mui/material';
import { m } from 'framer-motion';
import React from 'react';
import { useLocales } from 'src/locales';
import { varFade } from '../../../components/animate';
import MaillistPreview from '../../../components/conversion/MaillistPreview';
import Image from '../../../components/image';
import useResponsive from '../../../hooks/useResponsive';

export default function MorganFreeman() {
  const { t } = useLocales();
  const isDesktop = useResponsive('up', 'md');
  const isMobile = !isDesktop

  return (
    <Grid container spacing={isMobile ? 5 : 3} direction={isMobile ? 'column' : 'row'}
      alignItems={isMobile ? 'center' : 'flex-end'} justifyContent="center" marginTop={isMobile ? 0 : 5}>
      <Grid item xs={12} md={6} sx={{ textAlign: isMobile ? 'center' : 'inherit', display: 'flex', flexDirection: 'column', alignItems: 'flex-end', height: '100%'}}>
        {isMobile ? (
          <Image src="/assets/images/home/morgan_freeman_deepfake_detection_example.webp" alt="Morgan Freeman Deepfake Detection Technology Example" />
        ) : (
          <img src="/assets/images/home/morgan_freeman_deepfake_detection_example.webp" alt="Morgan Freeman Deepfake Detection Technology Example" />
        )}
      </Grid>
      <Grid item xs={12} md={6}>
        <m.div variants={varFade().inRight}>
          <Stack spacing={3}>
            <Typography component="h1" variant="h2"
              sx={{
                fontWeight: 'bold',
                background: (theme) =>
                  `-webkit-linear-gradient(260deg, ${theme.palette.primary.main} 0%, ${theme.palette.grey[800]} 100%)`,
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                textAlign: isMobile ? 'center' : 'inherit',
              }}
            >
              {t('landingpage.title')}
            </Typography>
            <Typography component="h2" variant="body1"
              sx={{ textAlign: isMobile ? 'center' : 'inherit', }}>
              {t('landingpage.morgan_freeman')}
            </Typography>
            <MaillistPreview />
          </Stack>
        </m.div>
      </Grid>
    </Grid>
  );
}
