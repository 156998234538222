import { Grid } from '@mui/material';
import React from 'react';
import { useLocales } from 'src/locales';
import GetDetectionGuide from '../../components/conversion/GetDetectionGuide';
import Affiliations from './landingPage/Affiliations';
import MorganFreeman from './landingPage/MorganFreeman';
import WhatMakesUsSpecial from './landingPage/WhatMakesUsSpecial';
import QandA from './landingPage/QandA';
import { supporters } from './utils/utils';

// ----------------------------------------------------------------------

export default function LandingPage() {
  const { t } = useLocales();
  return (
    <Grid container spacing={20}>
      <Grid item xs={12}>
        <MorganFreeman />
      </Grid>

      <Grid item xs={12}>
        <Affiliations affiliation_name={t('affiliations.supporters')} affiliations={supporters} />
      </Grid>

      <Grid item xs={12}>
        <WhatMakesUsSpecial />
      </Grid>

      <Grid item xs={12}>
        <GetDetectionGuide />
      </Grid>

      <Grid item xs={12}>
        <QandA />
      </Grid>
    </Grid>
  );
}
