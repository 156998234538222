import {
  de as deAdapter,
  enUS as enUSAdapter
} from 'date-fns/locale';
import merge from 'lodash/merge';
// core
import {
  deDE as deDECore,
  enUS as enUSCore
} from '@mui/material/locale';
// date-pickers
import {
  deDE as deDEDate,
  enUS as enUSDate
} from '@mui/x-date-pickers/locales';
// data-grid
import {
  deDE as deDEDataGrid,
  enUS as enUSDataGrid
} from '@mui/x-data-grid/locales';

// PLEASE REMOVE `LOCAL STORAGE` WHEN YOU CHANGE SETTINGS.
// ----------------------------------------------------------------------

export const allLangs = [
  {
    label: 'English',
    value: 'en',
    systemValue: merge(enUSDate, enUSDataGrid, enUSCore),
    adapterLocale: enUSAdapter,
    icon: 'flagpack:gb-nir',
  },
  {
    label: 'German',
    value: 'de',
    systemValue: merge(deDEDate, deDEDataGrid, deDECore),
    adapterLocale: deAdapter,
    icon: 'flagpack:de',
  },
  // {
  //   label: 'French',
  //   value: 'fr',
  //   systemValue: merge(frFRDate, frFRDataGrid, frFRCore),
  //   adapterLocale: frFRAdapter,
  //   icon: 'flagpack:fr',
  // },
];

export const defaultLang = allLangs[0]; // English

// GET MORE COUNTRY FLAGS
// https://icon-sets.iconify.design/flagpack/
// https://www.dropbox.com/sh/nec1vwswr9lqbh9/AAB9ufC8iccxvtWi3rzZvndLa?dl=0
