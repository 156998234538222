import { Card, CardContent, Grid, IconButton, Link, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import Iconify from "src/components/iconify";
import { useLocales } from "src/locales";
import { individualGradientTextStyle } from "../utils/utils";

const imageData = [
    {
        src: '/assets/images/about/employees/philipp.webp',
        name: 'Philipp Dewald',
        linkedin: 'https://www.linkedin.com/in/philipp-dewald/',
        mail: 'contact@detesia.com',
        title: 'CEO',
    },
    {
        src: '/assets/images/about/employees/peter.webp',
        name: 'Peter Stolz',
        linkedin: 'https://www.linkedin.com/in/peter-stolz/',
        mail: 'contact@detesia.com',
        title: 'CTO',
    },
    {
        src: '/assets/images/about/employees/tim.webp',
        name: 'Tim Walita',
        linkedin: 'https://www.linkedin.com/in/tim-walita/',
        mail: 'contact@detesia.com',
        title: 'COO',
    },
    {
        src: '/assets/images/about/employees/shreyash.webp',
        name: 'Shreyash Arya',
        linkedin: 'https://www.linkedin.com/in/shrebox/',
        title: 'AI Engineer',
    },
    {
        src: '/assets/images/about/employees/devikalyan.webp',
        name: 'Devikalyan Das',
        linkedin: 'https://www.linkedin.com/in/devikalyan-das-383b5a337/',
        title: 'AI Engineer',
    },
    {
        src: '/assets/images/about/employees/noshaba.webp',
        name: 'Noshaba Cheema',
        linkedin: 'https://www.linkedin.com/in/noshaba/',
        title: 'Research Assistant',
    },
    {
        src: '/assets/images/about/employees/naufil.webp',
        name: 'Muhammad Naufil',
        linkedin: 'https://www.linkedin.com/in/mnauf/',
        title: 'Research Assistant',
    },
    {
        src: '/assets/images/about/employees/ali.webp',
        name: 'Ali Azlan Aziz',
        linkedin: 'https://www.linkedin.com/in/aliazlanaziz/',
        title: 'Backend Developer',
    },
    // {
    // {
    //     src: '/assets/images/about/employees/naufil.webp',
    //     name: 'Ali Aziz Azlan',
    //     linkedin: 'https://www.linkedin.com/in/aliazlanaziz/',
    //     title: 'Research Assistant',
    // },
];

ImageCard.propTypes = {
    src: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    linkedin: PropTypes.string.isRequired,
    mail: PropTypes.string,
};

function ImageCard({ src, title, name, linkedin, mail, description }) {
    return (
        <Card>
            <img src={src} alt={description} style={{ width: '100%', height: 'auto' }} />
            <CardContent>
                <Stack style={{ alignContent: 'center', alignItems: 'center' }}>
                    <Typography variant="h6">
                        {name}
                    </Typography>
                    <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                        {title}
                    </Typography>
                    <Stack direction="row" spacing={1}>
                        <Link
                            key="Linkedin"
                            href={linkedin}
                            color="inherit"
                            variant="body2"
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            <IconButton>
                                <Iconify icon="eva:linkedin-fill" />
                            </IconButton>
                        </Link>
                        {mail && (
                            <Link
                                key="Email"
                                href={`mailto:${mail}`}
                                color="inherit"
                                variant="body2"
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                <IconButton>
                                    <Iconify icon="ph:envelope-simple-bold" />
                                </IconButton>
                            </Link>
                        )}
                    </Stack>
                    <Typography>{description}</Typography>
                </Stack>
            </CardContent>
        </Card >
    );
}

export default function Team() {
    const { t } = useLocales();
    return (
        <>
            <Typography variant="h2" style={individualGradientTextStyle}>
                {t('aboutuspage.team.title')}
            </Typography>
            <Grid container spacing={4} style={{ paddingTop: '20px' }}>
                {imageData.map((item, index) => (
                    <Grid item xs={12} sm={4} key={index}>
                        <ImageCard
                            src={item.src}
                            title={item.title}
                            name={item.name}
                            linkedin={item.linkedin}
                            mail={item.mail}
                            description={item.description}
                        />
                    </Grid>
                ))}
            </Grid>
        </>
    );
}
