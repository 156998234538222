import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { useLocales } from 'src/locales';

const gradientText = (theme) => ({
  fontWeight: 'bold',
  background: `-webkit-linear-gradient(260deg, ${theme.palette.primary.main} 0%, ${theme.palette.grey[800]} 100%)`,
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  pb: 3,
});

const Section = ({ title, image, alt, body, reverseDesktop = false, imageBox = false }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const imageContent = imageBox ? (
    <Box sx={{ height: 300, display: 'flex', justifyContent: 'center' }}>
      <img src={image} alt={alt} style={{ height: '100%', width: '100%', objectFit: 'contain' }} />
    </Box>
  ) : (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <img src={image} alt={alt} style={{ maxWidth: '100%', height: 'auto' }} />
    </Box>
  );

  return (
    <Grid container direction="row" alignItems="center" spacing={3} mt={5}>
      {isMobile ? (
        <>
          <Grid item xs={12}>
            <Typography variant="h3" sx={{ ...gradientText(theme), textAlign: 'center', }} >
              {title}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {imageContent}
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" textAlign="center">
              {body}
            </Typography>
          </Grid>
        </>
      ) : (
        <>
          {reverseDesktop ? (
            <>
              <Grid item xs={12} md={5}>
                {imageContent}
              </Grid>
              <Grid item xs={12} md={7}>
                <Typography variant="h3" sx={{ ...gradientText(theme), }} >
                  {title}
                </Typography>
                <Typography variant="body1">{body}</Typography>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12} md={7}>
                <Typography variant="h3" sx={{ ...gradientText(theme), }} >
                  {title}
                </Typography>
                <Typography variant="body1">
                  {body}
                </Typography>
              </Grid>
              <Grid item xs={12} md={5}>
                {imageContent}
              </Grid>
            </>
          )}
        </>
      )}
    </Grid>
  );
};

export default function WhatMakesUsSpecial() {
  const theme = useTheme();
  const { t } = useLocales();
  const sections = [
    {
      title: t('landingpage.what_makes_us_special.explainability.title'),
      image: '/assets/images/home/deepfake-detection-ai-visual-explanation.webp',
      alt: 'Visual explanation of deepfake detection results using AI',
      body: t('landingpage.what_makes_us_special.explainability.description'),
      reverseDesktop: false,
    },
    {
      title: t('landingpage.what_makes_us_special.multi_model.title'),
      image: '/assets/images/home/deepfake-detection-ai-models.webp',
      alt: 'Illustration of AI models used for deepfake detection across manipulation methods.',
      body: t('landingpage.what_makes_us_special.multi_model.description'),
      reverseDesktop: true,
      imageBox: true,
    },
    {
      title: t('landingpage.what_makes_us_special.comprehensive_insights.title'),
      image: '/assets/images/home/forensic-and-deepfake-detection-tools.webp',
      alt: 'Deepfake Detection with Reverse Search and Metadata Analysis',
      body: t('landingpage.what_makes_us_special.comprehensive_insights.description'),
      reverseDesktop: false,
    },
  ];

  return (
    <Box>
      <Box sx={{ textAlign: 'center', mb: 3 }}>
        <Typography variant="h2" sx={{ ...gradientText(theme), }} >
          {t('landingpage.what_makes_us_special.title')}
        </Typography>
      </Box>
      {sections.map((section, index) => (
        <Section key={index} {...section} />
      ))}
    </Box>
  );
}


Section.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  reverseDesktop: PropTypes.bool,
  imageBox: PropTypes.bool
};