import { Grid, Tooltip, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import Image from '../../../components/image';

export default function Affiliations({ affiliation_name, affiliations }) {

  return (
    <Grid container spacing={8} sx={{ justifyContent: 'center', alignItems: 'center' }}>
      <Grid item md={4} xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Typography variant="h2"
          sx={{
            fontWeight: 'bold',
            background: (theme) =>
              `-webkit-linear-gradient(260deg, ${theme.palette.primary.main} 0%, ${theme.palette.grey[800]} 100%)`,
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
          }}
        >
          {affiliation_name}
        </Typography>
      </Grid>
      <Grid item md={7} xs={12}>
        <Grid container spacing={4}>
          {Object.entries(affiliations).map(([key, value]) => (
            <Grid item xs={4} key={key} sx={{ display: 'flex', alignItems: 'center' }}>
              <a
                href={key}
                target="_blank"
                rel="noopener noreferrer"
                style={{ display: 'inline-block' }}
              >
                <Tooltip title={value[1]}>
                  <Image
                    alt={value[1]}
                    src={value[0]}
                    sx={{
                      height: 'auto',
                      width: 'auto',
                      objectFit: 'contain',
                      '&:hover': { transform: 'scale(1.1)' },
                    }}
                  />
                </Tooltip>
              </a>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}

Affiliations.propTypes = {
  affiliation_name: PropTypes.string.isRequired,
  affiliations: PropTypes.objectOf(
    PropTypes.arrayOf(PropTypes.string.isRequired).isRequired
  ).isRequired,
}