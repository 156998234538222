import { Card, Grid, Typography } from "@mui/material";
import React from "react";
import { useLocales } from "src/locales";

const gradientTextStyle = {
    fontWeight: 'bold',
    background: '-webkit-linear-gradient(260deg, #007bff 0%, #000 100%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    lineHeight: '1.15',
    paddingBottom: '30px',
};

export default function Storyline() {
    const { t } = useLocales();
    const missionSection = [
        {
            img: '/assets/images/about/detesia-research-team.webp',
            alt: 'Detesia’s research team in front of the CISPA coworking space',
            title: t('aboutuspage.storyline.section1.title'),
            intro_text: t('aboutuspage.storyline.section1.description.first'),
            text: t('aboutuspage.storyline.section1.description.second'),
        },
        {
            img: '/assets/images/about/cispa-research-center-detesia-origins.webp',
            alt: 'CISPA Helmholtz Center for Information Security building',
            title: t('aboutuspage.storyline.section2.title'),
            intro_text: t('aboutuspage.storyline.section2.description.first'),
            text: t('aboutuspage.storyline.section2.description.second'),
        },
    ];
    return (
        <Grid container spacing={7}>
            {missionSection.map((item, index) => (
                <Grid item xs={12}>
                    <Card sx={{ width: '100%', height: '100%' }}>
                        <Grid container direction="row" alignItems="center">
                            <Grid item lg={7} order={{ xs: (index % 2) + 1 }}>
                                <img src={item.img} alt={item.alt} style={{ width: '100%', height: 'auto' }} />
                            </Grid>
                            <Grid item lg={5} order={{ xs: 2 - (index % 2) }}>
                                <div style={{ margin: 30 }}>
                                    <Typography variant="h3" style={gradientTextStyle}>
                                        {item.title}
                                    </Typography>
                                    <Typography variant="body1" paddingBottom={2}>
                                        {item.intro_text}
                                    </Typography>
                                    <Typography variant="body1">
                                        {item.text}
                                    </Typography>
                                </div>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            ))}
        </Grid>
    );
}


