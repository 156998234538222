import { yupResolver } from '@hookform/resolvers/yup';
import {
  Alert,
  Button,
  Card,
  CardContent,
  CardHeader,
  Link,
  Snackbar,
  Stack,
  Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import axios from 'axios';
import { usePostHog } from 'posthog-js/react';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocales } from 'src/locales';
import { RouterLink } from 'src/routes/components';
import { paths } from 'src/routes/paths';
import * as Yup from 'yup';
import { BACKEND_API_URL } from '../../config-global';
import { useResponsive } from '../../hooks/use-responsive';
import FormProvider, { RHFTextField } from '../hook-form';

MailingSnackbar.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  formType: PropTypes.oneOf(['waitlist', 'guide']),
};
function MailingSnackbar({ open, handleClose, formType }) {
  const { t } = useLocales();
  const message =
    formType === 'waitlist'
      ? t('mailing_snackbar.waitlist')
      : t('mailing_snackbar.guide');
  return (
    <Snackbar
      open={open}
      autoHideDuration={5000} // Snackbar will auto-close after 5000ms (5 seconds)
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity="success" sx={{ width: '100%', marginTop: 8 }}>
        {message}
      </Alert>
    </Snackbar>
  );
}

MailingForm.propTypes = {
  burger: PropTypes.bool,
  formType: PropTypes.oneOf(['waitlist', 'guide']),
};

export function MailingForm({ burger = false, formType = 'waitlist' }) {
  const { t } = useLocales();
  const posthog = usePostHog();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const isDesktop = useResponsive('up', 'md');

  const Schema = Yup.object().shape({
    email: Yup.string()
      .trim()
      .email(t('register.email_invalid'))
      .required(t('register.email_required'))
      .max(200),
  });
  const methods = useForm({
    resolver: yupResolver(Schema),
  });
  const {
    setError,
    handleSubmit,
    reset,
  } = methods;

  const onSubmit = async (data) => {
    try {
      const endpoint =
        formType === 'guide'
          ? `${BACKEND_API_URL}contact/guide`
          : `${BACKEND_API_URL}contact/waitlist`;
      await axios.post(endpoint, data);
      posthog?.identify(data.email, {
        email: data.email,
      });
      posthog?.capture(formType === 'guide' ? 'guide_request' : 'waitlist_entry');
      setShowSnackbar(true);
      reset();
    } catch (error) {
      console.error(error);
      setError('afterSubmit', {
        ...error,
        message: error.message,
      });
    }
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setShowSnackbar(false);
  };

  // if burger set mx to 1 else omit
  const sx = {};
  if (burger) {
    sx.mx = 1;
  }

  return (
    <>
      <MailingSnackbar open={showSnackbar} handleClose={handleClose} formType={formType} />
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={1}>
          {!burger && !!isDesktop ? (
            <>
              <Stack direction="row" spacing={1}>
                <RHFTextField name="email" label="Email" />
                <Button type="submit" size="large" variant="contained" height="100">
                  {t('early_access.submit')}
                </Button>
              </Stack>
              <Typography variant='body2'>
                {t('early_access.privacy_remark_first')}{' '}
                <Link
                  component={RouterLink}
                  to={paths.privacy}
                  underline="always"
                  color="text.primary"
                >
                  {t('early_access.privacy_remark_second')}
                </Link>
              </Typography>
            </>
          ) : (
            <>
              <RHFTextField name="email" label="Email" />
              <Button type="submit" size="large" variant="contained" height="100">
                {t('early_access.submit')}
              </Button>
              <Typography variant='body2'>
                {t('early_access.privacy_remark_first')}{' '}
                <Link
                  component={RouterLink}
                  to={paths.privacy}
                  underline="always"
                  color="text.primary"
                >
                  {t('early_access.privacy_remark_second')}
                </Link>
              </Typography>
            </>
          )}
        </Stack>
      </FormProvider >
    </>
  );
}

export default function MaillistPreview({ formType = 'waitlist' }) {
  const { t } = useLocales();
  const theme = useTheme();

  return (
    <Card>
      <CardHeader
        title={t('early_access.title')}
        titleTypographyProps={{ color: theme.palette.primary.main }}
      />
      <CardContent>
        <MailingForm formType={formType} />
      </CardContent>
    </Card>
  );
}

MaillistPreview.propTypes = {
  formType: PropTypes.oneOf(['waitlist', 'guide']),
};