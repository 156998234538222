import { useLocales } from 'src/locales';
import { Helmet } from 'react-helmet-async';
// @mui
import { Container } from '@mui/material';
// sections
import LandingPage from '../sections/home/LandingPage';
import { MotionContainer } from '../components/animate';
import useResponsive from '../hooks/useResponsive';

// ----------------------------------------------------------------------

export default function HomePage() {
  const isDesktop = useResponsive('up', 'md');

  const { t } = useLocales();

  const faqs = [
    { q: t('landingpage.faq.q1'), a: t('landingpage.faq.a1') },
    { q: t('landingpage.faq.q2'), a: t('landingpage.faq.a2') },
    { q: t('landingpage.faq.q3'), a: t('landingpage.faq.a3') },
    { q: t('landingpage.faq.q4'), a: t('landingpage.faq.a4') },
    { q: t('landingpage.faq.q5'), a: t('landingpage.faq.a5') },
    { q: t('landingpage.faq.q6'), a: t('landingpage.faq.a6') },
    { q: t('landingpage.faq.q7'), a: t('landingpage.faq.a7') },
  ];

  const faqJsonLd = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": faqs.map(({ q, a }) => ({
      "@type": "Question",
      "name": q,
      "acceptedAnswer": {
        "@type": "Answer",
        "text": a,
      }
    })),
  };

  const orgAndWebsiteJsonLd = {
    "@context": "https://schema.org",
    "@graph": [
    {
     "@type": "Organization",
     "name": "Detesia",
     "url": "https://detesia.com/",
     "logo": "https://detesia.com/logo/detesia_logo.webp",
     "description": "Detesia's explainable deepfake detection empowers law enforcement, insurers & financial institutes to stay ahead in the fight against deepfake fraud.",
     "contactPoint": [{
       "@type": "ContactPoint",
       "contactType": "Contact Detesia",
       "email": "contact@detesia.com",
       "availableLanguage": ["en", "de"]
      }],
      "sameAs": [
         "https://www.linkedin.com/company/detesia"
                ]
     },
     {
      "@type": "WebSite",
      "url": "https://detesia.com/",
      "name": "Detesia – Deepfake Detection",
      "description": "Explainable deepfake detection for law enforcement, insurers, and financial institutes."
      }
      ]
  }

  const sx = isDesktop ? { padding: 10 } : { padding: 2, paddingTop: 10 };


  return (
    <>
      <Helmet>
        <title>Detesia - Deepfake Detection</title>
        <meta name="description" content="Detesia's explainable deepfake detection empowers law enforcement, insurers & financial institutes to stay ahead in the fight against deepfake fraud." />
        <link rel="canonical" href="https://detesia.com/" />

        {/* Open Graph */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://detesia.com/" />
        <meta property="og:title" content="Detesia – Deepfake Detection" />
        <meta property="og:description" content="Explainable deepfake detection for law enforcement, insurers, and financial institutions." />
        <meta property="og:image" content="https://detesia.com/logo/detesia_logo.webp" />
        <meta property="og:site_name" content="Detesia" />

        {/* JSON-D Stuff */}
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{__html: JSON.stringify(orgAndWebsiteJsonLd, null, 2),}} /* eslint-disable-line react/no-danger */
        />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{__html: JSON.stringify(faqJsonLd, null, 2),}} /* eslint-disable-line react/no-danger */
        />
      </Helmet>

      <Container component={MotionContainer} sx={sx}>
        <LandingPage />
      </Container>
    </>
  );
}
