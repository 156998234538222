// @mui
import { Card, CardContent, CardHeader, CardMedia, Grid, Link, Typography } from '@mui/material';
import React from 'react';
// routes
import PropTypes from 'prop-types';
import Markdown from 'react-markdown';
import Iconify from 'src/components/iconify';
import { useLocales } from 'src/locales';
import { individualGradientTextStyle } from '../utils/utils';

const latestPress = [
    {
        title: '1st Place at SpeedUpSecure: €10,000 Prize Awarded by Jury of Cybersecurity Executives from Lufthansa, GEA, HTGF & More',
        url: 'https://www.athene-center.de/speedupsecure-final-pitch-day',
        date: '24 June 2024',
        author: 'Athene',
        body: `Sieben innovative Gründungsvorhaben im Bereich Cybersecurity absolvierten erfolgreich das Accelerator-Programm „SpeedUpSecure“. Zum Abschluss des Programms präsentierten sie ihre Geschäftsidee vor einer hoch­karätigen Jury [Tolga Yilmaz, Cybersecurity Executive Advisor, Schwarz Digits KG; Naby Diaw, CISO Lufthansa AG; Iskro Mollov, CISO GEA Group; Michael Schrank, CISO; Dr. Maurice Kügler, Senior Investment Manager, High-Tech Gründerfonds Management GmbH; Guenter Kraft, CEO Xariva Ventures; David Kelm, Entrepreneur & Business Angel] und dem Fachpublikum. Die Gewinnerteams erhielten Preisgelder von insgesamt 17.500 EUR. Die drei bestplatzierten Startups: Trustlens [jetzt: Detesia] (Platz 1), InputLab (Platz 2) und VISS (Platz 3).

Auf den mit 10.000 EUR dotierten ersten Preis schaffte es das Spin-off Trustlens [jetzt: Detesia]. Die Jury begründete ihre Entscheidung damit, dass die Lösung eingängig ist und ein vor­handenes Problem konkret adressiert. Die beiden Gründer Philipp Dewald und Tim Walita bedankten sich mit den Worten: „Wir haben uns riesig über den Sieg beim Final Pitch Day gefreut und bedanken und herzlich bei allen Unterstützern. Die Expertensessions haben uns unglaublich viele neue Einblicke verschafft und das Netzwerken beim Kick-off sowie heute beim Final Pitch Day war absolut gewinnbringend.“
    `,
        description: `Das Accelerator-Programm „SpeedUpSecure“ fördert Startups mit innovativen Lösungen im Bereich Cybersecurity. Sieben Startups konnten sich für das Programm qualifizieren und durchliefen ab Mitte Mai das intensive Accelerator-Programm. Dabei erhielten sie Zugriff auf ein starkes Netzwerk an Expert*innen aus Wirtschaft und Forschung, die sich als Mentor*innen, Juror*innen, Coaches oder mit Impulsvorträgen im Accelerator engagieren. Der Gründungs­inkubator StartUpSecure am Nationalen Forschungszentrum für angewandte Cybersicherheit ATHENE in Darmstadt hat den Accelerator ins Leben gerufen.
    `,
        image: '/assets/images/about/detesia-speedupsecure-award.webp',
        alt: 'Detesia wins 1st place at SpeedUpSecure',
    },
];

// ----------------------------------------------------------------------

export default function AboutPress() {
    const { t } = useLocales();

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant="h2" style={individualGradientTextStyle}>
                    {t('aboutuspage.about_press.title')}
                </Typography>
            </Grid>
            <Grid item container xs={12} spacing={2}>
                {latestPress.map((item, index) => (
                    <Grid item xs={12} key={index}>
                        <Card>
                            <CardHeader title={item.title} titleTypographyProps={{ variant: 'h3' }} subheader={`Date: ${item.date}`} />
                            <CardContent>
                                <CardMedia component="img" image={item.image} style={{ maxWidth: '100%', height: 'auto', borderRadius: '16px' }} />
                                <Typography variant="h6" paddingTop={2}>
                                    {item.description}
                                </Typography>
                                <Markdown children={item.body} />
                                <PressLink url={item.url} />
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Grid>
    );
}

function PressLink({ url }) {
    const { t } = useLocales();
    return (
        <Link
            href={url}
            target="_blank"
            sx={{ display: 'flex', alignItems: 'center' }}
        >
            {t('aboutuspage.about_press.press_link')}
            <Iconify icon="ic:round-arrow-right-alt" sx={{ ml: 1 }} />
        </Link>
    );
}


PressLink.propTypes = {
    url: PropTypes.string.isRequired
}

